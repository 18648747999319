<template>
    <div class="ResponsiveContentBoard">
        <div v-if="!deviceEdit">
            <div class="mainTitle textAverage">Lista de Dispositivos</div>
            <div class="inputContainer">
                <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="deviceSearch">
                <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
            </div>
        </div>
        <div v-if="!deviceEdit" class="tableArea">
            <div class="tableInternalArea">
                <table class="tableNormal minSize textSmall">
                    <tr>
                        <th class="ResponsiveTableHead">Hardware ID</th>
                        <th class="ResponsiveTableHead">Descrição</th>
                        <th class="ResponsiveTableHead">Cliente</th>
                        <th class="ResponsiveTableHead">Unidade</th>
                        <th class="ResponsiveTableHead">CNPJ Unidade</th>
                        <th class="ResponsiveTableHead btnColumn"></th>
                    </tr>
                    <tr v-for="(device, index) in deviceListFilter" :key="index">
                        <td>{{device.hardwareId}}</td>
                        <td>{{device.description}}</td>
                        <td v-if="device.client != null">{{device.client.name}}</td>
                        <td v-else>{{'-'}}</td>
                        <td v-if="device.unity != null">{{device.unity.name}}</td>
                        <td v-else>{{'-'}}</td>
                        <td v-if="device.unity != null">{{device.unity.cnpj}}</td>
                        <td v-else>{{'-'}}</td>
                        <td class="ResponsiveCellConfig">
                        <div @click="editDevice(device)" class="btnIcon btnSmallSize btnTypeSave">
                            <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                        </div>
                        <div @click="deleteDevice(device)" class="btnIcon btnSmallSize btnTypeDelete">
                            <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                        </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <DeviceEdit v-else :device="device" :newDevice="newDevice" :unityEdit="false" @close="closeDeviceEdit()"></DeviceEdit>
        <div v-if="!deviceEdit" class="ResponsiveBtnConfigArea">
            <button @click="newDeviceBtn()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Novo</button>
            <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
        </div>
        <PopUp msg="Carregando lista de dispositivos..." :show="load" type="Loader"/>
        <PopUp :msg="faultMsg" :show="faultOnLoadDevices" type="Fault" :onOk="popUpOk"/>
    </div>
</template>

<script>
    import PopUp from '@/views/PopUp/PopUp.vue'
    import DeviceEdit from './DeviceEdit'
    import DeviceDAO from '@/sys/Control/Device/DeviceDAO'

    export default {
        components:{DeviceEdit, PopUp},
        methods:{
            async closeDeviceEdit(){
                this.device = null
                this.deviceEdit = false
                this.deviceList = await DeviceDAO.readAllActiveDevices();
            },
            newDeviceBtn(){
                this.deviceEdit = true
                this.newDevice = true
            },
            editDevice(device) {
                this.device = device
                this.newDevice = false
                this.deviceEdit = true
            },
            deleteDevice(device){
                this.deviceList = this.deviceList.filter( currentDevice => { return currentDevice.hardwareId != device.hardwareId})
                DeviceDAO.deleteDevice(device);
            },
            close(){
                this.$router.push({path: '/Setting/ChooseSetting'})
            },
            popUpOk: function(){
                this.faultOnLoadDevices=false;
            }
        },
        computed:{
            deviceListFilter(){
                if(this.deviceList != null) {
                    return this.deviceList.filter(device => {
                        if(device.client != null && device.unity != null) {
                            return device.client.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                                    || device.unity.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                                    || device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase())
                                    || device.unity.cnpj.includes(this.deviceSearch)
                        } else if(device.unity != null) {
                            return device.unity.name.toLowerCase().includes(this.deviceSearch.toLowerCase())
                                    || device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase())
                                    || device.unity.cnpj.includes(this.deviceSearch)
                        } else {
                            return device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase())
                        }
                    })
                }
            }
        },
        watch: {
            deviceList() {
            if(this.deviceList != null) {
                this.load = false;
            }
            }
        },
        data() {
            return {
                load: true,
                userSearch: '',
                deviceEdit: false,
                device: null,
                deviceList: null,
                deviceSearch: '',
                faultOnLoadDevices: false,
                faultMsg: null
            }
        },
        async created() {
            this.deviceList = await DeviceDAO.readAllActiveDevices().catch(e=>{
                this.load = false;
                this.faultMsg=e;
                this.faultOnLoadDevices=true;
            });
        }
    }  
</script>

<style scoped>
    .ResponsiveContentBoard {
        flex-flow: column nowrap;
    }
    .tableArea {
        flex-grow: 1;/* Define que a tabela é o unico item que pode mudar de tamanho */
        position: relative;
    }
    .tableInternalArea {
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 10px;/*Usado como paddin da tabela */
        right: 10px;/*Usado como paddin da tabela */
        background-color: #1e2227;
        overflow: auto;
    }
    @media(max-width: 825px){
        .tableInternalArea {
            top: 10px;
            left: 5px;/*Usado como paddin da tabela */
            right: 5px;/*Usado como paddin da tabela */
        }
    }
</style>